import React, { useEffect } from 'react';
import { Line } from '../../../components/Line';
import Header from '../../../components/Header';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import { sendAnalyticsData } from '../../../services/api/preferenceAPI';
import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { Grid } from '@mui/material';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { FormatTypes, helperFormat } from '../../../services/helper';
import { Subtitulo } from '../../../components/Titulo';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';
import { setData } from '../../../services/reducers/preferenceSlice';
import { fetchPosCon } from '../../../services/api/posConAPI';
// import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import { fetchResumoCart, fetchResumoCartPerf } from '../../../services/api/resumoCartAPI';
import { fetchIFsCaixa } from '../../../services/api/fluxoCaixaAPI';
import { fetchEstats } from '../../../services/api/estatsAPI';

interface Props {
  ignoraMesAtual?: boolean | null
  dispatchPDF?: boolean
}

export default function FluxoAtivos({ ignoraMesAtual, dispatchPDF }: Props) {
  const dispatch = useAppDispatch();
  const [obj_datas, fluxo_de_ativos, isLoaded, loading, loadedCarteiraList, isLoadingMinimized, params] = useAppSelector((state) => [
    state.fluxoAtivos.obj_datas,
    state.fluxoAtivos.fluxo_de_ativos,
    state.fluxoAtivos.isLoaded,
    state.fluxoAtivos.loading,
    state.preference.loadedCarteiraList,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
  ]);

  useEffect(() => {
    if(!dispatchPDF) document.title = `${process.env.REACT_APP_TABNAME} - Fluxo de Ativos`;
  }, []);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useEffect(() => {
    async function loadDados() {
      await dispatch(fetchFluxoAtivos());
    }
    if (!isLoaded && !loading && !dispatchPDF) {
      loadDados();

      dispatch(fetchResumoCart());
      dispatch(fetchResumoCartPerf());
      dispatch(fetchPerfHist());
      dispatch(fetchPosCon());
      // dispatch(fetchCartExp());
      dispatch(fetchIFsCaixa());
      dispatch(fetchEstats());
    }
  }, [isLoaded]);

  const rowsFluxoAtivos =
    isLoaded && fluxo_de_ativos
      ? fluxo_de_ativos.slice(1).map((row) => {
          let i = 0;
          const dataAux = row[i++] as string;
          return {
            data: dataAux.split('-').reverse().join('/'),
            tipo: row[i++],
            desc: row[i++],
            op: row[i++],
            quant: helperFormat(row[i++], FormatTypes.decimals, 2),
            valBruto: helperFormat(row[i++], FormatTypes.decimals, 2),
            valLiquido: helperFormat(row[i++], FormatTypes.decimals, 2),
          };
        })
      : null;

  const columnsFluxoAtivos: ITableColumns[] = [
    {
      id: 'data',
      label: 'Data',
      freeze: true,
      align: AlignTypes.left,
    },
    {
      id: 'tipo',
      label: 'Tipo',
      align: AlignTypes.left,
    },
    {
      id: 'desc',
      label: 'Descrição',
      align: AlignTypes.left,
    },
    {
      id: 'op',
      label: 'Operação',
      align: AlignTypes.left,
    },
    {
      id: 'quant',
      label: 'Quantidade',
      align: AlignTypes.right,
    },
    {
      id: 'valBruto',
      label: 'Valor Bruto',
      align: AlignTypes.right,
    },
    {
      id: 'valLiquido',
      label: 'Valor Líquido',
      align: AlignTypes.right,
    },
  ];

  return (
    <>
      <Grid>
      <Header title="Fluxo de Ativos" periodo obj_datas={obj_datas} />
      <Line />
      </Grid>
      <Grid container spacing={3}>
        {isLoaded ? (
          <Grid item xs={12}>
            <Subtitulo mb="8px">Movimentações</Subtitulo>
            {isLoaded && fluxo_de_ativos && fluxo_de_ativos.length > 1 ? (
              <EnhancedTable rows={rowsFluxoAtivos} columns={columnsFluxoAtivos} height={500} />
            ) : (
              <span>Nenhuma movimentação encontrada neste período</span>
            )}
          </Grid>
        ) : null}
      </Grid>
      <LoadingModal loading={((!loadedCarteiraList || !isLoaded) && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={(!loadedCarteiraList || !isLoaded) ?? false} isLoadingMinimized={isLoadingMinimized}/>
    </>
  );
}
